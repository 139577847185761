import React, {useEffect, useState} from "react";
import Axios from "axios";
import {Divider} from "antd";


import {Config} from "utils/Config";
import {GetTokenHeaders} from "common/ReusableFunction";
import AlertMessages from "utils/AlertMessage";
import {useParams} from "react-router-dom";

import LeftPanel from "./leftPanel";
import Header2 from "./header2";
import ViewRegistrationForm from "../expo/ViewRegistrationForm";

function RegistrationManagement() {
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(false);
    const apiURL = Config.apiURL;
    const [registrationDetails, setRegistrationDetails] = useState([]);
    const [documentLinks, setDocumentLinks] = useState([]);
    let [registrationStatus, setRegistrationStatus] = useState('');
    let [referenceNo, setReferenceNo] = useState('');


    let headers = GetTokenHeaders();


    useEffect(() => {
        getSeasonParticipantDetails().then(r => false);
    }, []);

    localStorage.removeItem("isAdmin");

    const getSeasonParticipantDetails = async () => {
        setLoading(true);
        try {
            const res = await Axios.get(apiURL + 'season-participant-details/' + id, headers);
            if (res.status === 200) {
                const {status, message, details} = res.data.data;
                if (status === 100) {
                    setRegistrationDetails(details);
                    const payload = {
                        searchValue: details.reference_no
                    }
                    getRegistration(payload);
                } else {
                    AlertMessages(status, message);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getRegistration = async (payLoad) => {
        setLoading(true)
        const res = await Axios.post(apiURL + 'view-registration', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                const links = res.data.data.documents;
                const regStatus = res.data.data.registration_status;
                const ref = res.data.data.reference_no;
                setDocumentLinks(links);
                setRegistrationStatus(regStatus)
                setReferenceNo(ref)
            } else {
                AlertMessages(status, message)
            }
            setLoading(false)
        }
    }


    return (
        <div className="bg-gray-200">
            {/* Fixed div at the top */}
            <Header2/>
            <LeftPanel/>

            {/* Adjusted content to not overlap with fixed div */}
            <div className="pt-16 ml-56 py-6">

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5">
                    <div
                        className="mt-3 bg-white rounded-md col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2">
                        <div className="font-bold text-center mt-2">My Details/Taarifa zangu</div>
                        <Divider className="mb-1 mt-1"/>
                        {registrationDetails &&
                            <table className="table-auto w-full bg-white divide-y divide-gray-200 mt-2 mb-5">
                                <tbody className="bg-white divide-y border-1 divide-gray-200 text-sm">
                                <tr>
                                    <td className="px-4 py-1 font-bold">Name</td>
                                    <td className="px-4 py-1 text-left">{`${registrationDetails.partcipant_name}`}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">TIN</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.tin}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Phone</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.phone}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Email</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.email}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Address</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.address}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Season</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.season_code}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Reference</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.reference_no}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Business Type</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.business_type}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Benefits</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.benefits}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Amount</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.amount}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Status</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.status}</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-1 font-bold">Control No</td>
                                    <td className="px-4 py-1 text-left">{registrationDetails.control_no ? registrationDetails.control_no : 'UTAPATA CONTROL NUMBER BAADA YA KUHAKIKIWA NA KAMATI'}</td>
                                </tr>
                                </tbody>
                            </table>

                        }

                    </div>

                    <div
                        className=" mt-3 card col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-3 xl:col-span-3 h-full">
                        <div className='ml-3 mr-2 rounded-xl bg-white border border-gray-200'>
                            <div
                                className='flex flex-col justify-between border-b bg-gray-50 py-3 rounded-md'>
                                <div className="ml-5 font-bold">Registration Form/Fomu ya Usajili</div>
                                <Divider className="mb-1 mt-1"/>
                                <div className="ml-5 mt-2">

                                    {documentLinks.registration_form &&
                                        <div>

                                            <div className="text-sm mb-2">
                                                Hali ya Usajlii: <b>{registrationStatus}</b>
                                            </div>

                                            <ViewRegistrationForm
                                                documentLink={documentLinks.registration_form}
                                                message='Hakuna taarifa za usajili'/>

                                        </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    );
}

export default RegistrationManagement;

import React from "react";

import {Divider} from "antd";

import {Link} from "react-router-dom";
import appLogo from "../../assets/images/logo/tz.png";


function Header() {


    return (

            <div className="fixed h-16 top-0 left-0 w-full bg-[#fb8c00] rounded-b-xl p-4 z-50">
                <div className="grid grid-cols-11">
                    <div className="col-span-1">
                        <Link to='/'>
                            <img
                                style={{boxShadow: '0 1px 4px rgba(0,0,0,.3), inset 0 0 40px rgba(0,0,0,.1)'}}
                                src={appLogo}
                                className="mr-7 -mt-4 h-16 w-16 rounded-full shadow-[#365e7d] shadow-md"
                                alt="Flowbite Logo"
                            />
                        </Link>
                    </div>
                    <div className="col-span-9 justify-center text-white -mt-3 font-bold text-xl">GEITA MINERAL EXHIBITION MANAGEMENT INFORMATION SYSTEM (GeMEMIS)
                        <Divider className="mt-1 mb-1"/>
                        <div className="flex justify-end">
                            <div className="flex col-span-10 justify-end text-gray-200 ">
                                <Link to='/presentation'
                                      className="bg-gray-100 border opacity-75 text-gray-600 text-sm px-2 py-0 rounded-md">Home</Link>
                            </div>
                            <div className="flex ml-2 col-span-10 justify-end text-gray-200 ">
                                <Link to='/registration'
                                      className="bg-gray-100 border opacity-75 text-gray-600 text-sm px-2 py-0 rounded-md">Registration/Usajili</Link>
                            </div>
                            {/*<div className="flex ml-2 col-span-10 justify-end text-gray-200 ">*/}
                            {/*    <Link to='/login'*/}
                            {/*          className="bg-gray-100 border opacity-75 text-gray-600 text-sm px-2 py-0 rounded-md">Sponsors/Wadhamini</Link>*/}
                            {/*</div>*/}
                            <div className="flex col-span-10 justify-end text-gray-200 ">
                                <Link to='/login'
                                      className="ml-2 bg-gray-600 text-white text-sm px-2 py-0 rounded-md">Login</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <Link to='/'>
                            <img
                                style={{boxShadow: '0 1px 4px rgba(0,0,0,.3), inset 0 0 40px rgba(0,0,0,.1)'}}
                                src={appLogo}
                                className="ml-10 -mt-4 h-16 w-16 rounded-full shadow-[#365e7d] shadow-md"
                                alt="Flowbite Logo"
                            />
                        </Link>
                    </div>

                </div>

            </div>

    );
}

export default Header;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ImageSlider from "../imageSlider";
import {Divider} from "antd";

// Images

function Information() {
    return (
        <div>
            <div className="grid grid-cols-5 px-2  gap-4">
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-l-3xl">
                    <Grid item xs={12} md={4} className="px-2">
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Mawasiliano"
                            description="Kamati ya uratibu, namba +255758180013
                                    Au barua pepe info@geita.go.tz"
                        />
                    </Grid>
                </div>
                <div className="col-span-3 px-2 py-2 rounded-md -mt-2 opacity-95"
                     style={{width: '100%', height: '100%', position: 'relative'}}>
                    <ImageSlider/>
                </div>
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-r-3xl">
                    <Grid item xs={12} md={4}>
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Namna ya Ulipaji"
                            description="Baada ya usajili wako kuidhinishwa, utatumiwa Control Number kwa njia ya sms na email"
                        />
                    </Grid>
                </div>
            </div>
            <Divider/>
            <div className="grid grid-cols-2 px-2 mt-5  gap-4">
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-xl">

                    <Grid item xs={12} md={4}>
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Ubora wa Bidhaa"
                            description="Katika maonesho haya bidhaa zako zinatakiwa kukidhi viwango vya ubora vya Shirika la Viwango Tanzania (TBS) na Mamlaka ya Dawa na Vifaa Tiba (TMDA) na pia kuzingatia tamko la ushindani wa haki katika biashara."
                        />
                    </Grid>
                </div>
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-xl">

                    <Grid item xs={12} md={4}>
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Taratibu, Kusitisha Maombi na Uhalali"
                            description="Maombi haya ni Mkataba baina ya Mamlaka ya kamati ya Uratibu wa Maonesho haya na mshiriki wa maonesho. Ikitokea muoneshaji akaamua kujitoa wakati mkataba huu umekwisha ridhiwa, malipo yake hayatorudishwa."
                        />
                    </Grid>
                </div>
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-xl">

                    <Grid item xs={12} md={4}>
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Mwisho wa Kuthibitisha Ushiriki"
                            description="Tarehe ya mwisho kuthibitisha ushiriki na kukamilisha malipo ya ushiriki ni Tarehe 15 September, 2023 (Nafasi zitatolewa kwa kuzingatia wa kwanza kuomba wa kwanza kufikiriwa."
                        />
                    </Grid>
                </div>
                <div className="col-span-1 bg-gray-100 px-4 py-2 rounded-xl">

                    <Grid item xs={12} md={4}>
                        <DefaultInfoCard
                            icon="devices"
                            color='warning'
                            title="Uthibitisho/Tamko"
                            description="Nakubali kuwa nimesoma maelezo hapo juu na kwamba uamuzi utakaofanywa na Kamati ya Uratibu utakuwa ni uamuzi ya mwisho. Na kuwa nimekubali Kampuni/ Taasisi yangu kushiriki kwenye maonesho haya, kwa kuzingatia kanuni na taratibu za maonesho kama zitakavyowekwa."
                        />
                    </Grid>
                </div>
            </div>
        </div>
    )
        ;
}

export default Information;

import React from "react";

import {Divider} from "antd";

import {Link} from "react-router-dom";


function LeftPanelAdmin({switchTab, currentTab}) {


    return (

        <div className="fixed h-full mt-16 left-0 w-52 bg-[#efefef] rounded-r-xl p-4 z-50 overflow-auto">
            <div className="flex flex-col h-full mb-10">

                <Divider className="mt-1 mb-1"/>
                <div className="flex flex-col space-y-2">
                    <Link
                        to="/pages/system-admin/admin-panel"
                        className={` hover:bg-yellow-700 hover:text-gray-50  text-gray-600 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        Home
                    </Link>
                    <button
                        onClick={() => {
                            switchTab('dashboard')
                        }}
                        className=" border-0 hover:bg-yellow-700 hover:text-gray-50  text-gray-600 text-sm px-2 py-1 rounded-md text-left"
                    >
                        Dashboard
                    </button>
                    <Divider/>
                    <div className="text-sm font-light text-yellow-700">Business</div>
                    <Divider/>
                    <button
                        onClick={() => {
                            switchTab('events')
                        }}
                        className={` ${currentTab === 'events' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 hover:text-gray-50  text-gray-800 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        <div className="font-bold">Event Seasons</div>
                        <div className="font-light">Misimu</div>
                    </button>

                    <button
                        onClick={() => {
                            switchTab('business-types')
                        }}
                        className={` ${currentTab === 'business-types' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 hover:text-gray-50 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        <div className="font-bold">Business Types</div>
                        <div className="font-light">Aina za Biashara</div>
                    </button>
                    <button
                        onClick={() => {
                            switchTab('registrations')
                        }}
                        className={` ${currentTab === 'registrations' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 hover:text-gray-50 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        <div className="font-bold">Registrations</div>
                        <div className="font-light">Usajili</div>
                    </button>
                    {/*<button*/}
                    {/*    onClick={() => {*/}
                    {/*        switchTab('approvals')*/}
                    {/*    }}*/}
                    {/*    className={` ${currentTab === 'approvals' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Approvals</div>*/}
                    {/*    <div className="font-light">Kuidhinisha</div>*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*    onClick={() => {*/}
                    {/*        switchTab('incomplete')*/}
                    {/*    }}*/}
                    {/*    className={` ${currentTab === 'incomplete' ? 'bg-gray-300' : ''}  border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Incomplete</div>*/}
                    {/*    <div className="font-light">Sajili zisizokamilika</div>*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*    onClick={() => {*/}
                    {/*        switchTab('completed')*/}
                    {/*    }}*/}
                    {/*    className={` ${currentTab === 'completed' ? 'bg-gray-300' : ''}   border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Completed</div>*/}
                    {/*    <div className="font-light">Sajili zilizokamilika</div>*/}
                    {/*</button>*/}
                    <Divider/>
                    <div className="text-sm font-light text-yellow-700">System Admin</div>
                    <Divider/>
                    <button
                        onClick={() => {
                            switchTab('sponsors')
                        }}
                        className={` ${currentTab === 'sponsors' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 hover:text-gray-50  text-gray-800 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        <div className="font-bold">Sponsors</div>
                        <div className="font-light">Wadhamin</div>
                    </button>
                    {/*<Link*/}
                    {/*    to="/registration"*/}
                    {/*    className={` ${currentTab === 'registration' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left`}*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Payment Details</div>*/}
                    {/*    <div className="font-light">Taarifa za Malipo</div>*/}
                    {/*</Link>*/}

                    <Divider/>
                    <div className="text-sm font-light text-yellow-700">Accountant</div>
                    <Divider/>
                    <button
                        onClick={() => {
                            switchTab('control-no')
                        }}
                        className={` ${currentTab === 'control-no' ? 'bg-gray-300' : ''} border-0 hover:bg-yellow-700 hover:text-gray-50  text-gray-800 text-sm px-2 py-1 rounded-md text-left`}
                    >
                        <div className="font-bold">Control Number</div>
                        <div className="font-light">Namba ya malipo</div>
                    </button>
                    {/*<Link*/}
                    {/*    to="/registration"*/}
                    {/*    className=" border-0 hover:bg-yellow-700  text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Registration Details</div>*/}
                    {/*    <div className="font-light">Taarifa za Usajili</div>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    to="/registration"*/}
                    {/*    className=" border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Payment Details</div>*/}
                    {/*    <div className="font-light">Taarifa za Malipo</div>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    to="/login"*/}
                    {/*    className=" border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Contact Support Team</div>*/}
                    {/*    <div className="font-light">Omba Msaada</div>*/}
                    {/*</Link>*/}
                    <Divider/>
                    <div className="text-sm font-light text-yellow-700">View Only</div>
                    <Divider/>
                    {/*<Link*/}
                    {/*    to="/registration"*/}
                    {/*    className=" border-0 hover:bg-yellow-700  text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Registration Details</div>*/}
                    {/*    <div className="font-light">Taarifa za Usajili</div>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    to="/registration"*/}
                    {/*    className=" border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Payment Details</div>*/}
                    {/*    <div className="font-light">Taarifa za Malipo</div>*/}
                    {/*</Link>*/}
                    {/*<Link*/}
                    {/*    to="/login"*/}
                    {/*    className="border-0 hover:bg-yellow-700 text-gray-800 text-sm px-2 py-1 rounded-md text-left"*/}
                    {/*>*/}
                    {/*    <div className="font-bold">Contact Support Team</div>*/}
                    {/*    <div className="font-light">Omba Msaada</div>*/}
                    {/*</Link>*/}
                </div>

            </div>
        </div>

    );
}

export default LeftPanelAdmin;
